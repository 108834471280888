<template>
  <div class="page page__error error">
    <current-portal-logo :is-with-title="false" />
    <h3>
      <span>Страница не найдена.</span>
      <esmp-link to="/">
        Перейти на главную.
      </esmp-link>
    </h3>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CurrentPortalLogo from '@/layouts/current-portal-logo/';

export default {
  name: 'Error',
  components: {
    CurrentPortalLogo,
  },
  methods: {
    ...mapActions('system', ['forceLogout']),
  },
};
</script>

<style lang="scss">
.error {
  min-height: calc(100vh - #{$header-height});
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-between;
  align-items: center;
  padding: 16px 0;

  @include for-size(phone-portrait-down) {
    background-color: $color-white;
    padding: 16px 8px;
  }

  .current-portal-logo {
    margin-bottom: 40px;

    svg {
      width: 50px;
      height: 50px;
      margin-right: 0;
    }
  }

  .esmp-link {
    font-size: inherit;
    text-decoration: underline;
    margin-left: 6px;
  }
}
</style>
